import React from 'react';
import styled from 'styled-components';

import LightPage from '../../components/LightPage';
import { TextSection, Declaration, SectionHeading } from '../../components/textComponents';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Northeast Ohio',
];

const page = {
  title: 'Thank You For Applying',
  keywords: keywords,
};

const heading = {
  title: page.title,
};

export default () => (
  <LightPage page={page} heading={heading}>
    <TextSection css={`
      margin-bottom: 60rem;
    `}>
      <SectionHeading>Thank You!</SectionHeading>
      <Declaration>
        Thank you for applying for a position with VLP Landscaping!  We will review your application and contact you to discuss the next steps!
      </Declaration>
    </TextSection>
  </LightPage>
);